/* eslint-disable max-len */
const { help } = require('./help');
const { translateSecurityIssues } = require('./securityIssues');

const actionsForPasswordPage = i18n => ({
  submit: () => i18n.gettext('Iniciar sesión'),
  recover_pass: () => i18n.gettext('¿Olvidaste tu contraseña?'),
});

const actionsForReauthPasswordPage = i18n => ({
  submit: () => i18n.gettext('Continuar'),
  recover_pass: () => i18n.gettext('¿Olvidaste tu contraseña?'),
});

const defaultPassword = (i18n) => ({
  buttons: actionsForPasswordPage(i18n),
  /* i18n-next-line except: ["en"] */
  title: (_, platformName) => i18n.gettext('Ingresa tu contraseña de {0}', platformName),
});

const defaultWording = (i18n, type) => ({
  registered: {
    type,
    password: defaultPassword(i18n),
  },
  not_registered: {
    type,
    password: defaultPassword(i18n),
  },
  password_greet: {
    type,
    password: {
      title: (user, platformName) => i18n.gettext('¡Hola, {0}! Ingresa tu contraseña de {1}', [user, platformName]),
      buttons: actionsForPasswordPage(i18n),
    },
  },
  password_reauth: {
    type,
    password: {
      title: () => i18n.gettext('Para continuar ingresa tu contraseña'),
      buttons: actionsForReauthPasswordPage(i18n),
    },
  },
});

const defaultMshopStoreWording = (i18n, type) => ({
  type,
  password: {
    ...defaultPassword(i18n),
    /* i18n-next-line except: ["en"] */
    payment_and_shipping: i18n.gettext('Procesamos el pago y envío con tu cuenta de'),
  },
});

const assembleWording = i18n => ({
  help: help(i18n),
  securityIssues: translateSecurityIssues(i18n),
  registered: {
    default: defaultWording(i18n, 'default').registered,
    explicit: defaultWording(i18n, 'login').registered,
    registration_v0: defaultWording(i18n, 'registration_v0').registered,
    registration_v3: defaultWording(i18n, 'registration_v3').registered,
    favorite: {
      type: 'favorite',
      password: defaultPassword(i18n),
    },
    question: {
      type: 'question',
      password: defaultPassword(i18n),
    },
    quote: {
      type: 'quote',
      password: defaultPassword(i18n),
    },
    share: {
      type: 'share',
      password: defaultPassword(i18n),
    },
    print: {
      type: 'print',
      password: defaultPassword(i18n),
    },
    publish: {
      type: 'publish',
      password: defaultPassword(i18n),
    },
    download: {
      type: 'download',
      password: defaultPassword(i18n),
    },
    contact: {
      type: 'contact',
      password: defaultPassword(i18n),
    },
    sell: {
      type: 'sell',
      password: defaultPassword(i18n),
    },
    buy: {
      type: 'buy',
      password: defaultPassword(i18n),
    },
    view_cart: {
      type: 'view_cart',
      password: defaultPassword(i18n),
    },
    add_cart: {
      type: 'add_cart',
      password: defaultPassword(i18n),
    },
    checkout_buy: {
      type: 'checkout_buy',
      password: defaultPassword(i18n),
    },
    checkout_auction: {
      type: 'checkout_auction',
      password: defaultPassword(i18n),
    },
    advance_real_state: {
      type: 'advance_real_state',
      password: defaultPassword(i18n),
    },
    advance_services: {
      type: 'advance_services',
      password: defaultPassword(i18n),
    },
    advance_motors: {
      type: 'advance_motors',
      password: defaultPassword(i18n),
    },
    callme_real_state: {
      type: 'callme_real_state',
      password: defaultPassword(i18n),
    },
    point_redeem_coupon: defaultWording(i18n, 'point_redeem_coupon').registered,
    google_play: defaultWording(i18n, 'google_play').registered, // not used but to avoid 500
    buy_ms_store: defaultMshopStoreWording(i18n, 'buy_ms_store'),
    mshops_high_risk: defaultMshopStoreWording(i18n, 'mshops_high_risk'),
    consumer_credits: {
      type: 'consumer_credits',
      password: defaultPassword(i18n),
    },
    negative_traffic: {
      type: 'negative_traffic',
      password: defaultPassword(i18n),
    },
    cbt: defaultWording(i18n, 'cbt').registered,
    point_buy: {
      type: 'point_buy',
      password: {
        buttons: actionsForReauthPasswordPage(i18n),
        title: defaultPassword(i18n).title,
      },
    },
    vis_motor_contact: defaultWording(i18n, 'vis_motor_contact').registered,
    vis_credits_simulation: {
      type: 'vis_credits_simulation',
      password: defaultPassword(i18n),
    },
    faqs: defaultWording(i18n, 'faqs').registered,
    digital_sus: {
      type: 'digital_sus',
      password: {
        /* i18n-next-line except: ["pt-BR", "en"] */
        title: () => i18n.gettext('Para activar tus beneficios, ingresa tu contraseña'),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    ifpe: {
      type: 'ifpe',
      password: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet ingresa tu contraseña'),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    registration_link: defaultWording(i18n, 'registration_link').registered,
    checkout_pro: defaultWording(i18n, 'checkout_pro').registered,
  },
  not_registered: {
    default: defaultWording(i18n, 'default').not_registered,
    explicit: defaultWording(i18n, 'login').not_registered,
    registration_v0: defaultWording(i18n, 'registration_v0').not_registered,
    registration_v3: defaultWording(i18n, 'registration_v3').not_registered,
    favorite: {
      type: 'favorite',
      password: defaultPassword(i18n),
    },
    question: {
      type: 'question',
      password: defaultPassword(i18n),
    },
    quote: {
      type: 'quote',
      password: defaultPassword(i18n),
    },
    share: {
      type: 'share',
      password: defaultPassword(i18n),
    },
    print: {
      type: 'print',
      password: defaultPassword(i18n),
    },
    publish: {
      type: 'publish',
      password: defaultPassword(i18n),
    },
    download: {
      type: 'download',
      password: defaultPassword(i18n),
    },
    contact: {
      type: 'contact',
      password: defaultPassword(i18n),
    },
    sell: {
      type: 'sell',
      password: defaultPassword(i18n),
    },
    buy: {
      type: 'buy',
      password: defaultPassword(i18n),
    },
    view_cart: {
      type: 'view_cart',
      password: defaultPassword(i18n),
    },
    add_cart: {
      type: 'add_cart',
      password: defaultPassword(i18n),
    },
    checkout_buy: {
      type: 'checkout_buy',
      password: defaultPassword(i18n),
    },
    checkout_auction: {
      type: 'checkout_auction',
      password: defaultPassword(i18n),
    },
    advance_real_state: {
      type: 'advance_real_state',
      password: defaultPassword(i18n),
    },
    advance_services: {
      type: 'advance_services',
      password: defaultPassword(i18n),
    },
    advance_motors: {
      type: 'advance_motors',
      password: defaultPassword(i18n),
    },
    callme_real_state: {
      type: 'callme_real_state',
      password: defaultPassword(i18n),
    },
    point_redeem_coupon: {
      type: 'point_redeem_coupon',
      password: defaultPassword(i18n),
    },
    google_play: { // not used but to avoid 500
      type: 'google_play',
      password: defaultPassword(i18n),
    },
    buy_ms_store: defaultMshopStoreWording(i18n, 'buy_ms_store'),
    mshops_high_risk: defaultMshopStoreWording(i18n, 'mshops_high_risk'),
    consumer_credits: {
      type: 'consumer_credits',
      password: defaultPassword(i18n),
    },
    negative_traffic: {
      type: 'negative_traffic',
      password: defaultPassword(i18n),
    },
    cbt: defaultWording(i18n, 'cbt').not_registered,
    point_buy: {
      type: 'point_buy',
      password: {
        buttons: actionsForReauthPasswordPage(i18n),
        title: defaultPassword(i18n).title,
      },
    },
    vis_motor_contact: {
      type: 'vis_motor_contact',
      password: defaultPassword(i18n),
    },
    vis_credits_simulation: {
      type: 'vis_credits_simulation',
      password: defaultPassword(i18n),
    },
    faqs: defaultWording(i18n, 'faqs').not_registered,
    digital_sus: {
      type: 'digital_sus',
      password: {
        title: defaultPassword(i18n),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    ifpe: {
      type: 'ifpe',
      password: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet ingresa tu contraseña'),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    registration_link: defaultWording(i18n, 'registration_link').not_registered,
    checkout_pro: defaultWording(i18n, 'checkout_pro').not_registered,
  },
  errors: {
    email_not_found: () => i18n.gettext('Revisa el dato que ingresaste.'),
    nickname_not_found: () => i18n.gettext('Revisa el dato que ingresaste.'),
    /* i18n-next-line except: ["en"] */
    phone_not_found: () => i18n.gettext('Este número de teléfono no está asociado a una cuenta'),
    missing_response: () => i18n.gettext('Completa este dato.'),
    user_required: () => i18n.gettext('Completa este dato.'),
    user_incomplete_registration: () => {
      const settings = {
        tags: {
          0: '<a class="input-error__link" onClick="window.onIncompleteRegistrationClick()">',
          1: '</a>',
        },
      };

      return i18n.jsx.gettext(
        'Tu cuenta todavía no existe. {0}Completa los pasos{1} para terminar de crearla.',
        settings,
      );
    },
    captcha_word_required: () => i18n.gettext('Completa el reCAPTCHA.'),
    captcha_response_invalid: () => i18n.gettext('Completa el reCAPTCHA.'),
    invalid_user_site: () => i18n.gettext('Ingresaste un e-mail de '),
    /* i18n-next-line except: ["en"] */
    user_site_platform_mismatch: () => i18n.gettext('Ingresaste un e-mail de otra plataforma.'),
    operator_not_allowed: () => i18n.gettext('Como operador de la cuenta, no tienes permiso para ingresar.'),
    server_error: () => i18n.gettext('¡Ups! Hubo un problema. Vuelve a intentarlo.'),
    /* i18n-block-start except: ["en", "pt-BR"] */
    email_invalid: () => i18n.gettext('El dato que ingresaste no es un e-mail.'),
    email_mismatch: () => i18n.gettext('Este e-mail no coincide con el de tu cuenta.'),
    social_error: () => i18n.gettext('Ocurrió un error, vuelve a intentarlo.'),
  },
  recaptcha: {
    label: {
      identification: i18n.gettext('Completa este paso para continuar'),
      password: i18n.gettext('Completa esta paso para iniciar sesión'),
      /* i18n-block-end */
    },
    protected_by: () => i18n.gettext('Protegido por reCAPTCHA'),
    privacy: () => i18n.gettext('Privacidad'),
    conditions: () => i18n.gettext('Condiciones'),
  },
  notFound: {
    titleMp: i18n.gettext('Lo que querías ver no existe o lo movimos de lugar'),
    titleMl: i18n.gettext('Parece que esta página no existe'),
    mpLink: i18n.gettext('Ver operaciones'),
  },
  serverError: {
    titleMp: i18n.gettext('Oh, no, algo anduvo mal'),
    titleMl: i18n.gettext('¡Ups! Hubo un error'),
    descriptionMp: i18n.gettext('Ya empezamos a trabajar en solucionarlo.'),
    descriptionMl: i18n.gettext('Inténtalo de nuevo más tarde'),
  },
  footer: {
    link: () => i18n.gettext('Cómo cuidamos tu privacidad (abrirá una nueva ventana)'),
    copyright: (year, isIfpe) => (
      isIfpe
        /* i18n-next-line except: ["en", "pt-BR"] */
        ? i18n.gettext('Este Canal de Instrucción o ambiente es operado por Mercado Libre, S.A. de C.V., Institución de Fondos de Pago Electrónico, sociedad autorizada para operar como institución de fondos de pago electrónico que proporciona los servicios relacionados con tu cuenta Mercado Pago Wallet.')
        : i18n.gettext('Copyright © 1999-{0} MercadoLibre S.R.L.', year) /* i18n-this-line except: ["en"] */
    ),
  },
});

module.exports.wordings = i18n => assembleWording(i18n);
exports.defaultWording = defaultWording;
exports.defaultPassword = defaultPassword;
exports.actionsForReauthPasswordPage = actionsForReauthPasswordPage;
exports.actionsForPasswordPage = actionsForPasswordPage;
exports.defaultMshopStoreWording = defaultMshopStoreWording;
