import { AuthFlow, LoginVersion, NOT_APPLY, SHOPS, challengeViews } from '../data/enums';
import { isMshopsBU } from '../utils/businessUnit';
import { isV2LoginViewMode } from '../utils/supports';
import { TRACK_KEY } from './trackKeys';

/**
 * @param  {"changeAccount" | "help" | "securityProblem"} track
 * @param  {import("../data/navigation").Navigation & import("../data/challengeSchema").ChallengeSchema} context
 * @return {import('./trackEvent').LoginAuthChallengeEventData}
 */
const getEventData = (track, context) => {
  const { schema, navigation, request, visibilityPassword, availableSocialMethods } = context;

  const { trackingId: tracking_id, user } = schema?.transaction ?? {};
  const viewMode = schema.content_configuration?.layout_view_mode;
  const commonData = {
    challenge: challengeViews[schema.type],
    tracking_id,
    journey_id: request?.login?.journeyId,
    source: `MSL_${navigation?.loginType?.value?.toUpperCase()}`,
    reauthentication: Boolean(navigation?.authFlow === AuthFlow.REAUTH),
    ...(Boolean(user?.accountModel) && { account_model: user?.accountModel }),
  };
  const version = isV2LoginViewMode(viewMode) && {
    version: LoginVersion.v2,
  };

  const event = {
    [TRACK_KEY.IDENTIFICATION]: {
      ...commonData,
      ...(!request?.device?.webView && { available_social_methods: availableSocialMethods || NOT_APPLY }),
    },
    [TRACK_KEY.PASSWORD]: {
      ...commonData,
      ...(!request?.device?.webView && { available_social_methods: NOT_APPLY }),
    },
    [TRACK_KEY.SUBMIT]: commonData,
    [TRACK_KEY.DECLINE]: commonData,
    [TRACK_KEY.ERROR]: { ...commonData },
    [TRACK_KEY.CHANGE_ACCOUNT]: { ...commonData, ...version },
    [TRACK_KEY.HELP]: { ...commonData, ...version },
    [TRACK_KEY.CREATE_ACCOUNT]: { ...commonData },
    [TRACK_KEY.SECURITY_PROBLEM]: {
      challenge: commonData.challenge,
      tracking_id: commonData.tracking_id,
      code: 'security_problem',
    },
    [TRACK_KEY.PASSWORD_VISIBILITY]: {
      ...commonData,
      visibility_status: visibilityPassword,
    },
  };

  return event[track];
};

const trackPath = {
  [TRACK_KEY.CHANGE_ACCOUNT]: '/login/auth/challenge/change_account',
  [TRACK_KEY.HELP]: '/login/auth/challenge/help',
  [TRACK_KEY.SECURITY_PROBLEM]: '/login/challenge/complaint/execute',
  [TRACK_KEY.PASSWORD_VISIBILITY]: '/login/auth/challenge/password_visibility',
  [TRACK_KEY.CREATE_ACCOUNT]: '/login/auth/challenge/create_account',
  [TRACK_KEY.IDENTIFICATION]: '/login/auth/challenge',
  [TRACK_KEY.PASSWORD]: '/login/auth/challenge',
  [TRACK_KEY.SUBMIT]: '/login/auth/challenge/submit',
  [TRACK_KEY.DECLINE]: '/login/auth/challenge/decline',
  [TRACK_KEY.ERROR]: '/login/auth/challenge/error',
};

/**
* @param  {"changeAccount" | "help" | "securityProblem"} track
* @param  {import("../data/navigation").Navigation & import("../data/challengeSchema").ChallengeSchema} context
*/
const getTrackingData = (track, context) => {
  const { request } = context;
  const isShops = isMshopsBU(request?.login?.navigation?.loginType?.value);

  return {
    path: trackPath[track],
    event_data: getEventData(track, context),
    application: {
      business: isShops ? SHOPS.MELIDATA.BUSINESS : request?.platform?.business,
      site_id: request?.platform?.siteId,
    },
  };
};

export default { getTrackingData, trackPath };
