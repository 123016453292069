const React = require('react');
const PropTypes = require('prop-types');
const Hotjar = require('nordic/hotjar');
const { isMshops } = require('../../services/utils/isMshops');


const MercadoShopsHotjar = ({ loginType }) => isMshops(loginType) && <Hotjar id={425766} />;

MercadoShopsHotjar.propTypes = {
  loginType: PropTypes.string.isRequired,
};

module.exports = MercadoShopsHotjar;
