/* eslint-disable security/detect-unsafe-regex, security/detect-non-literal-regexp */
const React = require('react');
/**
 * @description Split the text and return found regex in text and remainder.
 * @param  {string} text
 * @param  {RegExp} regExpToFind
 * @returns {Array<string>}
 */
const splitByRegex = (text, regExpToFind) => {
  const [target] = regExpToFind.exec(text) || [];
  const [initialText, finalText] = text.split(target);

  return [initialText, target, finalText];
};

const textBetweenParentheses = /\(([^)]+)\)/;
const maskedTextsAroundTheCursor = (mask, cursorPos) => new RegExp(
  `(^\\${mask}{1,${cursorPos}})|(\\${mask}+[\\s\\S]?)`, 'g',
);

const allMaskedText = (mask) => new RegExp(`(\\${mask}+)`, 'g');

const withNoWrapWord = (text, target, { isSocialLogin } = {}) => {
  const isPlatformNameIncluded = text.includes(target);
  if (!isPlatformNameIncluded || isSocialLogin) return () => <>{text}</>;

  const [firstPhrase, secondPhrase] = text.split(target);

  return (props) => (
    <>{firstPhrase}<span {...props} className="no-wrap-text">{target}</span>{secondPhrase}</>
  );
};

const sessionId = /^ghy-\d{6}-\w+-__-\d+-__-\d+--[A-Z]+_\d+-[A-Z]+_\d+$/;

const scopedSessionId = /^scp-\d{6}-\w+-\d+-\d{10}-\w+$/;

const uuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const onlyHexChars = /[^0-9A-Fa-f]/g;

const ticketSSO = /^[A-Za-z0-9-_=/]+$/;

const schemaReaderIdentification = {
  PHONE: {
    MLB: /^(?:\+55[\s.-]?)?(?:\(?[1-9][1-9]\)?[\s.-]?)?9(?:\d{8}|(?:[\s.-]?\d{4}){2})$/,
    DEFAULT: /^[0-9()[\].\-#*/+ ]{8,}$/,
  },
  EMAIL: /.+@.+/,
  LEGAL_ID: {
    MLB: /^(\b\d{3}[.]\d{3}[.]\d{3}[-]\d{2}\b)|(\b\d{9}[-]\d{2}\b)|(\b\d{11}\b)$/,
    MLA: /^(?=(?:[^0-9]*[0-9]){7,8}[^0-9]*$)[0-9., ]+$/,
  },
};

module.exports = {
  splitByRegex,
  regex: {
    textBetweenParentheses,
    maskedTextsAroundTheCursor,
    allMaskedText,
    sessionId,
    scopedSessionId,
    uuid,
    isEmail,
    onlyHexChars,
    schemaReaderIdentification,
    ticketSSO,
  },
  withNoWrapWord,
};
