/* eslint-disable react/destructuring-assignment */
/* eslint camelcase:0 */
/* globals window document newrelic meli_ga */
const React = require('react');
const { shape, bool, string, arrayOf } = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const { Head } = require('nordic/head');
const { Script } = require('nordic/script');
const restClient = require('nordic/restclient');
const serialize = require('serialize-javascript');
const Appearance = require('frontend-layout/src/utils/mercadoshops/appearance');
const { Button } = require('@andes/button');
const { Snackbar } = require('@andes/snackbar');
const Textfield = require('@andes/textfield');
const { Form } = require('@andes/form');
const { MelidataTrack } = require('nordic/melidata/melidata-track');

const RegistrationLink = require('../../../components/RegistrationLink');
const { loginTypes, ErrorCodes } = require('../../../../services/data/enums');
const StyleAsset = require('../../../components/StyleAsset');
const CenterCard = require('../../../components/CenterCard');
const Recaptcha = require('../../../components/Recaptcha');
const ProfilingScriptContainer = require('../../../components/ProfilingScriptContainer');
const EmailInput = require('../../../components/EmailInput');
const LoginGA = require('../../../components/LoginGA');
const MercadoShopsHotjar = require('../../../components/MercadoShopsHotjar');
const CheckoutGA = require('../../../components/CheckoutGA');
const WordingManager = require('../../../wording-manager');
const ReplaceUrl = require('../../../components/ReplaceUrl');
const TextHeader = require('../../../components/TextHeader');
const RecaptchaTerms = require('../../../components/RecaptchaTerms');
const MercadoShopsScripts = require('../../../components/MercadoShopsScripts');
const { withMaskedValue } = require('../../../components/hooks/textfieldMask');
const InputError = require('../../../components/InputError');
const { trackEvent } = require('../../../../services/tracks');
const { SecurityProblem } = require('../../../components/SecurityIssues');

const TextfieldWithMask = withMaskedValue(Textfield);

const ERROR_SNACKBAR_ANIMATION_DELAY = 3000;
class EmailNicknameView extends React.Component {
  constructor(props) {
    super(props);
    this.wording = WordingManager.wordings(props.i18n);
    const errors = this.filterErrors(props.errors);
    this.originalInputValue = props.user || '';
    this.recaptchaRequired = props.recaptcha.required;
    const schema = props.schemaType.replace('_with_recaptcha', '').replace('_or_social_login', '');
    this.wordingType = ['MCO', 'MLA', 'MLM', 'MLB', 'MLC', 'MLU']
      .find(site => site === props.platform.siteId) ? schema : schema.replace('_or_phone', '');
    this.state = {
      value: props.user,
      errorsInput: errors.inputErrors,
      errorsRecaptcha: errors.recaptchaErrors,
      track: '',
    };
    this.enableFormSubmitButton();
  }

  handleChange(value) {
    if (value !== this.originalInputValue) {
      this.setState({ errorsInput: [] });
    } else {
      this.setState({ errorsInput: this.originalInputErrors });
    }
    if (value !== undefined) {
      this.setState({ value });
    }
  }

  handleKeyUp(event) {
    const { track } = this.state;
    if (event.which === 17 || event.which === 91) {
      this.setState({
        track: `${track}u${event.which}`,
      });
    }
  }

  onSubmit(event) {
    const { lowEnd } = this.props;
    if (!lowEnd) {
      event.preventDefault();
      this.preventMultipleSubmits(() => {
        this.submitForm();
      });
    }
  }

  getValidationErrors(errors) {
    let validationErrors = '';
    errors.inputErrors.forEach(error => { validationErrors += `${error.cause},`; });
    errors.recaptchaErrors.forEach(error => { validationErrors += `${error.cause},`; });
    if (validationErrors.length > 0) {
      validationErrors = validationErrors.slice(0, validationErrors.length - 1);
    }

    return validationErrors.toUpperCase();
  }

  getUserData() {
    const { value, track } = this.state;
    const { profiling, schemaType } = this.props;
    const data = {
      user_id: value,
      dps: profiling.id,
      kstrs: track,
      type: schemaType,
    };
    const recaptcha = document.getElementById('g-recaptcha-response');
    if (recaptcha) {
      data['g-recaptcha-response'] = recaptcha.value;
    }
    const recaptchaToken = document.getElementById('gctkn');
    if (recaptchaToken) {
      data.gctkn = recaptchaToken.value;
    }

    return data;
  }

  getRequestConfig() {
    return {
      baseURL: window.location.origin,
      headers: {
        Accept: 'application/json',
        ContentType: 'application/json',
      },
      timeout: 10000,
    };
  }

  sendDataToGA(errors) {
    if (meli_ga) {
      const errorsDescription = this.getValidationErrors(errors);
      meli_ga('send', 'event', 'LOGIN', 'USER_IDENTIFICATION_ERROR', errorsDescription);
    }
  }

  filterErrors(errors) {
    const inputErrors = [];
    const recaptchaErrors = [];
    errors.map(({ ...e }) => {
      if (e.cause === ErrorCodes.USER_INCOMPLETE_REGISTRATION) {
        e.actionUrl = `${this.props.urls.incompleteRegistrationUrl}/${this.state?.value || this.props?.user}`;
      }
      e.text = this.wording.errors[e.cause]();

      return e.code === ErrorCodes.RECAPTCHA
        ? recaptchaErrors.push(e)
        : inputErrors.push(e);
    });

    this.originalInputErrors = inputErrors || [];

    return {
      inputErrors,
      recaptchaErrors,
    };
  }

  submitForm() {
    const { value } = this.state;
    const { action } = this.props;
    this.originalInputValue = value;
    restClient(this.getRequestConfig()).post(action.apiCallbackUrl, { data: this.getUserData() })
      .then((response) => {
        this.enableFormSubmitButton();
        if (response.data.redirect_url) {
          window.location.href = response.data.redirect_url;
        } else if (response.data.errors) {
          const errors = this.filterErrors(response.data.errors);
          this.sendDataToGA(errors);
          if (window.grecaptcha && this.recaptchaRequired) {
            if (window.grecaptcha.enterprise) {
              window.grecaptcha.enterprise.reset();
            } else {
              window.grecaptcha.reset();
            }
          }
          this.setState({
            value,
            errorsInput: errors.inputErrors,
            errorsRecaptcha: errors.recaptchaErrors,
          });
        }
      })
      .catch((error) => {
        this.enableFormSubmitButton();
        const filteredErrors = this.filterErrors([{ cause: 'server_error' }]);
        this.setState({
          value,
          errorsInput: filteredErrors.inputErrors,
        });
        if (typeof newrelic !== 'undefined') {
          newrelic.noticeError(new Error(error.response || error.message));
        }
      });
  }

  preventMultipleSubmits(run) {
    if (!this.formButtonPressed) {
      run();
      this.disableFormSubmitButton();
      setTimeout(() => { this.enableFormSubmitButton(); }, 10000);
    }
  }

  enableFormSubmitButton() {
    this.formButtonPressed = false;
  }

  disableFormSubmitButton() {
    this.formButtonPressed = true;
  }

  render() {
    const {
      schemaType,
      translations,
      registered,
      action,
      actualUrl,
      errors,
      user,
      recaptcha,
      profiling,
      platform,
      mercadoShopsData,
      navigation,
      lowEnd,
      mobile,
      preconnectUrls,
      securityProblemLink,
      showRegistrationLink,
      suggestions,
      hideTermAndConditions,
      cspNonce,
      promiseTimeoutError,
      usernameVisibility,
      helpUrl,
      trackingId,
      envIsProduction,
      csrfToken,
      urls,
    } = this.props;

    const {
      value,
      errorsInput,
      errorsRecaptcha,
      track,
    } = this.state;

    const preconnectProps = JSON.stringify({ urls: preconnectUrls });
    const wordingContext = this.wording[registered][navigation.login_type]
      ? this.wording[registered][navigation.login_type][this.wordingType]
      : this.wording[registered][loginTypes.DEFAULT][this.wordingType];
    const { title, buttons } = wordingContext;
    // subtitulo específico de mercadoShops
    const appearance = mercadoShopsData && new Appearance(mercadoShopsData.appearance);
    const mercadoShopsConfiguration = mercadoShopsData ? {
      name: mercadoShopsData.shop.name,
      widgets: appearance.getComponentProperty('Widgets', 'widgets'),
    } : [];
    const helpInfo = {
      href: helpUrl,
      text: this.wording.faqs_section.link_to_login_faqs(),
      onClick: () => trackEvent(this.props.track.help),
    };
    const inputName = 'user_id';
    const inputErrorProps = {
      viewName: inputName,
      schemaType,
      trackingId,
      errors: errorsInput,
      loginType: navigation.login_type,
    };
    const secondaryActionButton = [loginTypes.HYBRID_GUEST, loginTypes.LOGIN_CHOICE].includes(navigation.login_type)
      ? buttons.continueAsGuestUser()
      : buttons.register();
    const MessageFeedback = <InputError {...inputErrorProps} />;

    return (
      <div className="email_nickname_view container">
        <LoginGA
          mercadoShopsData={mercadoShopsData}
          navigation={navigation}
          page="ID/USER"
          errors={errors}
          recaptcha={recaptcha}
        />
        <CheckoutGA
          navigation={navigation}
          step="USER"
          mobile={mobile}
        />
        <MelidataTrack path="/dummy" isDeferred /> { /* loads melidata scripts */}
        <MercadoShopsHotjar loginType={navigation.login_type} />
        <Head>
          <title>{title()}</title>
          <meta name="robots" content="noindex, nofollow" />
        </Head>
        <StyleAsset platformId={platform.id} viewName="email_nickname" />
        <Script>
          {`
            window.__PRELOADED_STATE__ = ${serialize(
            {
              schemaType,
              translations,
              registered,
              action,
              actualUrl,
              errors,
              user,
              recaptcha,
              profiling,
              mercadoShopsData,
              platform,
              navigation,
              lowEnd,
              mobile,
              preconnectUrls,
              securityProblemLink,
              showRegistrationLink,
              suggestions,
              hideTermAndConditions,
              cspNonce,
              promiseTimeoutError,
              usernameVisibility,
              helpUrl,
              trackingId,
              envIsProduction,
              track: this.props.track,
              urls,
              csrfToken,
            }, { isJSON: true },
          )};
          `}
        </Script>
        <CenterCard
          helpInfo={helpInfo}
          platform={platform}
          mercadoShopsData={mercadoShopsData}
          wordingContext={wordingContext}
        >
          <div className="center-card__header">
            <TextHeader title={title()} />
          </div>
          <Form
            className="login-form"
            action={action.schemaUrl}
            method="POST"
            noValidate
            id="login_user_form"
          >
            <div className="login-form__row">
              {usernameVisibility
                ? (
                  <EmailInput
                    type="email"
                    name={inputName}
                    autocomplete
                    value={value}
                    errors={errorsInput}
                    label={this.wording.labels[this.wordingType]()}
                    onChange={v => this.handleChange(v)}
                    onKeyUp={e => this.handleKeyUp(e)}
                    onComponentDidUpdate={v => this.handleChange(v)}
                    suggestions={suggestions}
                    message={MessageFeedback}
                  />
                )
                : (
                  <TextfieldWithMask
                    initialValue={user}
                    id={inputName}
                    autoCapitalize="none"
                    autoComplete="new-password"
                    autoCorrect="off"
                    autoFocus
                    label={this.wording.labels[this.wordingType]()}
                    maxLength="120"
                    message={MessageFeedback}
                    messageShow
                    mobile={mobile}
                    modifier={errorsInput.length > 0 ? 'error' : 'default'}
                    name={inputName}
                    onChange={v => this.handleChange(v)}
                    onKeyUp={e => this.handleKeyUp(e)}
                    spellCheck="false"
                  />
                )}
              <Recaptcha
                required={recaptcha.required}
                key={recaptcha.key}
                action={recaptcha.action}
                shouldRunScore={recaptcha.shouldRunScore}
                errors={errorsRecaptcha}
                lowEnd={lowEnd}
                isRunningTest={recaptcha.isRunningTest}
                cspNonce={cspNonce}
                envIsProduction={envIsProduction}
                siteId={recaptcha.siteId}
              />
            </div>
            <div className="login-form__actions">
              <Button
                type="submit"
                className="login-form__actions--submit"
                onClick={e => this.onSubmit(e)}
                fullWidth
              >{buttons.submit()}
              </Button>
              {showRegistrationLink && (
                <RegistrationLink
                  id="registration-link"
                  className="login-form__actions--register"
                  text={secondaryActionButton}
                  url={urls.registration}
                  track={this.props.track.createAccount}
                />
              )}
            </div>
            <input
              type="hidden"
              name="_csrf"
              id="_csrf"
              value={csrfToken}
            />
            <input
              type="hidden"
              id="kstrs"
              name="kstrs"
              value={track}
              ref={(kstrs) => { this.kstrs = kstrs; }}
            />
            <input
              type="hidden"
              name="dps"
              id="dps"
              value={profiling.id}
            />
            <input
              type="hidden"
              id="gctkn"
              name="gctkn"
            />
            <input
              type="hidden"
              id="type"
              name="type"
              value={schemaType}
            />
          </Form>
        </CenterCard>
        <SecurityProblem
          link={securityProblemLink}
          navigation={navigation}
          platform={platform}
          wordings={this.wording.securityIssues}
          track={this.props.track}
        />
        <RecaptchaTerms
          siteId={platform.siteId}
          hideTermAndConditions={hideTermAndConditions}
          required={recaptcha.required}
        />
        <Snackbar
          color="red"
          className="error-snackbar"
          message={this.wording.errors.server_error()}
          show={promiseTimeoutError}
          delay={ERROR_SNACKBAR_ANIMATION_DELAY}
        />
        <ProfilingScriptContainer profiling={profiling} />
        <ReplaceUrl url={actualUrl} />
        <div id="preconnect-props" data-props={preconnectProps} />
        <Script src="email_nickname.js" />
        { mercadoShopsData && <MercadoShopsScripts mShopsScripts={mercadoShopsConfiguration.widgets} />}
      </div>
    );
  }
}

EmailNicknameView.propTypes = {
  csrfToken: string,
  schemaType: string.isRequired,
  translations: shape().isRequired,
  registered: string.isRequired,
  action: shape().isRequired,
  mercadoShopsData: shape(),
  actualUrl: string.isRequired,
  errors: arrayOf(string),
  user: string,
  recaptcha: shape({
    required: bool.isRequired,
    key: string,
    action: string.isRequired,
    shouldRunScore: bool,
  }).isRequired,
  profiling: shape().isRequired,
  platform: shape().isRequired,
  navigation: shape().isRequired,
  lowEnd: bool,
  mobile: bool,
  preconnectUrls: arrayOf(string).isRequired,
  securityProblemLink: shape({
    href: string,
  }),
  showRegistrationLink: bool.isRequired,
  suggestions: arrayOf(shape({
    name: string,
  })),
  hideTermAndConditions: bool.isRequired,
  i18n: shape().isRequired,
  cspNonce: string.isRequired,
  promiseTimeoutError: bool,
  usernameVisibility: bool,
  helpUrl: string,
  track: shape({
    help: shape(),
    securityProblem: shape({
      challenge: string,
      tracking_id: string,
      code: string,
    }),
  }),
  trackingId: string,
  urls: shape({
    incompleteRegistrationUrl: string,
    registration: string,
  }),
  envIsProduction: bool,
};

EmailNicknameView.defaultProps = {
  errors: [],
  user: '',
  profiling: {},
  mobile: false,
  suggestions: [],
  cspNonce: '',
  promiseTimeoutError: false,
  usernameVisibility: true,
};

module.exports = injectI18n(EmailNicknameView);
