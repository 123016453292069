import { loginTypes } from '../data/enums';

const {
  BUY_MS_STORE,
  MSHOPS_HIGH_RISK,
  GUEST_LOGIN_ML,
  GUEST_LOGIN_MP,
  HYBRID_GUEST,
  LOGIN_CHOICE,
  MSHOPS_GUEST_LIGHT_LOGIN,
} = loginTypes;

const SHOPS_LOGIN_TYPES = [
  BUY_MS_STORE,
  MSHOPS_HIGH_RISK,
  GUEST_LOGIN_ML,
  GUEST_LOGIN_MP,
  HYBRID_GUEST,
  LOGIN_CHOICE,
  MSHOPS_GUEST_LIGHT_LOGIN,
];

const isMshops = (loginType) => SHOPS_LOGIN_TYPES.includes(loginType);

const shouldEnableV2 = (loginType) => [
  MSHOPS_GUEST_LIGHT_LOGIN,
].includes(loginType);

export { isMshops, shouldEnableV2, SHOPS_LOGIN_TYPES };
